
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import NewCardModal from "@/components/modals/forms/NewCardModal.vue";
import PaymentRecords from "@/components/customers/cards/overview/PaymentRecords.vue";
import PaymentMethods from "@/components/customers/cards/overview/PaymentMethods.vue";
import CreditBalance from "@/components/customers/cards/overview/CreditBalance.vue";
import Invoices from "@/components/customers/cards/overview/Invoices.vue";

import Events from "@/components/customers/cards/events-and-logs/Events.vue";
import Logs from "@/components/customers/cards/events-and-logs/Logs.vue";

import Earnings from "@/components/customers/cards/statments/Earnings.vue";
import Statement from "@/components/customers/cards/statments/Statement.vue";

export default defineComponent({
    name: "customer-details",
    components: {
        PaymentRecords,
        PaymentMethods,
        CreditBalance,
        Invoices,
        Events,
        Logs,
        Earnings,
        Statement,
        Dropdown3,
        NewCardModal,
    },
    setup() {
        onMounted(() => {
            MenuComponent.reinitialization();
            setCurrentPageBreadcrumbs("Customer Details", ["Apps", "Customers"]);
        });

        return {};
    },
});
