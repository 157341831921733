
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "logs",
  components: {},
  props: {
    cardClasses: String,
  },
  setup() {
    const logs = ref([
      {
        code: "200 OK",
        color: "success",
        message: "POST /v1/noninvoices/in_5806_7068/payment",
        date: "10 Mar 2021, 11:05 am",
      },
      {
        code: "404 WRN",
        color: "warning",
        message: "POST /v1/customer/in_9092_7391/not_found",
        date: "15 Apr 2021, 6:43 am",
      },
      {
        code: "200 OK",
        color: "success",
        message: "POST /v1/noninvoices/in_9757_6897/payment",
        date: "15 Apr 2021, 6:43 am",
      },
      {
        code: "500 ERR",
        color: "danger",
        message: "POST /v1/invoice/c_6140090460c97/invalid",
        date: "10 Nov 2021, 2:40 pm",
      },
      {
        code: "200 OK",
        color: "success",
        message: "POST /v1/noninvoices/c_6140090460c99/payment",
        date: "05 May 2021, 10:10 pm",
      },
      {
        code: "500 ERR",
        color: "danger",
        message: "POST /v1/invoice/c_6140090460c97/invalid",
        date: "20 Dec 2021, 11:05 am",
      },
      {
        code: "200 OK",
        color: "success",
        message: "POST /v1/noninvoices/in_5806_7068/payment",
        date: "10 Mar 2021, 8:43 pm",
      },
      {
        code: "404 WRN",
        color: "warning",
        message: "POST /v1/customer/c_6140090460c99/not_found",
        date: "20 Dec 2021, 2:40 pm",
      },
      {
        code: "200 OK",
        color: "success",
        message: "POST /v1/noninvoices/c_6140090460c97/payment",
        date: "25 Oct 2021, 11:05 am",
      },
      {
        code: "404 WRN",
        color: "warning",
        message: "POST /v1/customer/in_8377_7366/not_found",
        date: "19 Aug 2021, 6:43 am",
      },
      {
        code: "200 OK",
        color: "success",
        message: "POST /v1/noninvoices/in_6370_5796/payment",
        date: "25 Oct 2021, 11:05 am",
      },
      {
        code: "500 ERR",
        color: "danger",
        message: "POST /v1/invoice/in_9092_7391/invalid",
        date: "20 Dec 2021, 11:05 am",
      },
    ]);

    return {
      logs,
    };
  },
});
